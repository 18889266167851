import { useCallback, useEffect, useMemo, useState } from 'react';
import { ClientFormUser } from '../../models/ClientFormUser';
import User from '../../models/User';
import { InputSuggestion } from '../shared/form-control/InputSuggestions';
import { ClientFormUserRole, ClientFormUserRoleKeys } from '../../models/ClientFormUserRoles';
import DropdownSelect from '../shared/form-control/DropdownSelect';
import { Option } from '../Option';
import { roleOptions } from './RoleOptions';
import { Trans, useTranslation } from 'react-i18next';
import Button, { ButtonType } from '../shared/form-control/Button';
import UserAccessRow from './UserAccessRow';
import { FCWithChildren } from '../../types/FCWithChildren';
import { ImageSize, ProfileImageStack } from './ProfileImageStack';
import InviteOrSearchContent from '../user/InviteOrSearchContent';
import { useRecoilValue } from 'recoil';
import { Roles } from '../../models/Role';
import UserInvitation from '../../models/UserInvitation';
import { currentClientAtom } from '../../recoil/atoms/Clients';

type AccessControlProps = {
  users: ClientFormUser[];
  clientUsers: User[];
  onUsersChange: (users: ClientFormUser[]) => void;
  requireValidator?: boolean;
  requireApprover?: boolean;
  requireOwner?: boolean;
  shouldValidateRoles?: boolean;
  disableAdd?: boolean;
  creatorRole?: (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole];
  onCreatorRoleChange?: (role: (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole] | null) => void;
  formStepId?: string;
  inviteMode?: boolean;
  onUserInviteChange?: (user: UserInvitation) => void;
  onUserInviteValid?: (isValid: boolean) => void;
  excludeOwnerRole?: boolean;
  isValid: boolean;
  usersMaxHeight?: string;
};

const noAccessValue = -1;

const AccessControl: FCWithChildren<AccessControlProps> = (props) => {
  const {
    users,
    clientUsers,
    onUsersChange,
    requireValidator,
    requireApprover,
    requireOwner,
    shouldValidateRoles = true,
    disableAdd = false,
    creatorRole,
    onCreatorRoleChange,
    formStepId,
    inviteMode,
    onUserInviteChange,
    onUserInviteValid,
    excludeOwnerRole,
    isValid,
    usersMaxHeight = 'calc(100vh - 39rem)',
  } = props;
  const { t } = useTranslation(['common']);
  const currentClient = useRecoilValue(currentClientAtom);
  const [formRoleOptions, setFormRoleOptions] = useState<Option<string, string | number>[]>([]);
  const [role, setRole] = useState<(typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]>(ClientFormUserRole.Viewer);
  const [userSearch, setUserSearch] = useState('');
  const [selectedUser, setSelectedUser] = useState<ClientFormUser | null>(null);
  const filteredRoles = useMemo(() => {
    return formRoleOptions
      .filter((role) => {
        // Filter out Owner form role if already assigned
        if (role.value === ClientFormUserRole.Owner) {
          if (excludeOwnerRole || users.find((x) => x.role === ClientFormUserRole.Owner) || creatorRole === ClientFormUserRole.Owner) {
            return false;
          }
        }
        return true;
      })
      .filter((role) => {
        return !requireApprover ? role.value !== ClientFormUserRole.Approver : true;
      })
      .filter((role) => {
        return !requireValidator ? role.value !== ClientFormUserRole.Validator : true;
      })
      .filter((role) => {
        const stepRoles = [ClientFormUserRole.Contributor, ClientFormUserRole.Viewer, noAccessValue];
        if (formStepId && requireApprover) {
          stepRoles.push(ClientFormUserRole.Approver);
        }
        return !formStepId ? true : stepRoles.indexOf(role.value as number) > -1;
      })
      .map((role) => ({
        id: role.id,
        value: role.value,
        text:
          role.value === noAccessValue
            ? role.text
            : t(ClientFormUserRoleKeys[role.value as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]]),
      }));
  }, [formRoleOptions, excludeOwnerRole, users, creatorRole, requireApprover, requireValidator, formStepId, t]);

  const formRoles = useMemo(() => filteredRoles.filter((x) => x.value !== noAccessValue), [filteredRoles]);

  const onRoleChange = (value: (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]) => {
    setRole(value);
    if (selectedUser) {
      selectedUser.role = value;
    }
  };

  const suggestions = useMemo(() => {
    return clientUsers
      .filter((x) => users.findIndex((u) => u.id === x.id) === -1)
      .map((user) => ({
        id: user.id as string,
        value: user.id as string,
        text: user.firstName && user.lastName ? `${user.firstName} ${user.lastName}` : `${user.email}`,
      }));
  }, [clientUsers, users]);

  useEffect(() => {
    setFormRoleOptions([
      ...roleOptions.map((x) => {
        return {
          id: x.id,
          text: t(ClientFormUserRoleKeys[x.value as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]]),
          value: x.value,
        };
      }),
      { id: noAccessValue.toString(), value: noAccessValue, text: t('common:form-role.no-access') },
    ]);
  }, [t]);

  const findRole = (value: number) => {
    const role = formRoleOptions.find((role) => role.value === value);
    if (!role) {
      return undefined;
    }

    return {
      id: role.id,
      value: role.value,
      text: role.text,
    };
  };

  const assignedUserRoleChange = (user: ClientFormUser, value: (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]) => {
    onUsersChange([
      ...users.map((assignedUser) => {
        if (assignedUser.id === user.id) {
          return { ...assignedUser, role: value, formSectionId: formStepId };
        }
        return assignedUser;
      }),
    ]);
  };

  const unAssignUser = (user: ClientFormUser) => {
    onUsersChange([...users.filter((x) => x.id !== user.id)]);
  };

  const onSelectUser = useCallback(
    (option: Option<string, string | number>) => {
      setUserSearch(option.text);
      const user = clientUsers.find((u) => u.id === option.id) as ClientFormUser;
      setSelectedUser(user);
    },
    [clientUsers],
  );

  const assignUser = useCallback(() => {
    if (selectedUser) {
      selectedUser.role = role;
      selectedUser.formSectionId = formStepId;
      onUsersChange([...users, selectedUser]);
      // reset
      setUserSearch('');
      setSelectedUser(null);
      setRole(ClientFormUserRole.Viewer);
    }
  }, [formStepId, onUsersChange, role, selectedUser, users]);

  const inviteRoleFilter = useCallback((role: Option<string, string>) => {
    const roles = [Roles.TeamLead, Roles.TeamMember, Roles.Management, Roles.Employee, Roles.ExternalContributor];
    return roles.indexOf(role.value as Roles) > -1;
  }, []);

  const requiredRoles = useMemo(() => {
    const roles = [];
    if (requireApprover) {
      roles.push(t(ClientFormUserRoleKeys[ClientFormUserRole.Approver]));
    }
    if (requireValidator) {
      roles.push(t(ClientFormUserRoleKeys[ClientFormUserRole.Validator]));
    }
    if (requireOwner) {
      roles.push(t(ClientFormUserRoleKeys[ClientFormUserRole.Owner]));
    }
    return roles;
  }, [requireApprover, requireOwner, requireValidator, t]);

  const multiRoleMessage = useMemo(() => {
    if (requiredRoles.length == 1) {
      return (
        <Trans
          t={t}
          i18nKey="permissions-modal.requires-single-role"
          components={{
            Bold: <span className="font-medium" />,
            Att: <span className="text-semantic-2 font-medium" />,
            role: requiredRoles[0],
          }}
        />
      );
    }
    if (requiredRoles.length == 2) {
      return (
        <Trans
          t={t}
          i18nKey="permissions-modal.requires-multi-roles"
          components={{
            Bold: <span className="font-medium" />,
            Att: <span className="text-semantic-2 font-medium" />,
            roles: requiredRoles[0],
            lastRole: requiredRoles[1],
          }}
        />
      );
    }
    if (requiredRoles.length > 2) {
      const lastRole = requiredRoles.pop();
      const commaSeparatedRoles = requiredRoles.join(', ');
      return (
        <Trans
          t={t}
          i18nKey="permissions-modal.requires-multi-roles"
          components={{
            Bold: <span className="font-medium" />,
            Att: <span className="text-semantic-2 font-medium" />,
          }}
          values={{ roles: commaSeparatedRoles, lastRole: lastRole }}
        />
      );
    }
  }, [requiredRoles, t]);

  if (!inviteMode) {
    return (
      <>
        {!disableAdd && (
          <div className="mb-4 flex gap-4">
            <InputSuggestion
              data-cy="user-search"
              className="w-full"
              inputConfig={{
                value: userSearch,
                placeholder: t('add-or-invite-modal.add.search'),
              }}
              onChange={setUserSearch}
              onPick={onSelectUser}
              suggestions={suggestions}
            />

            {formRoles && (
              <DropdownSelect
                wrapperClassName=""
                className="min-w-40"
                data-cy="form-permission"
                value={findRole(role)}
                onChange={(data) => onRoleChange(data.value as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole])}
                options={formRoles}
                disabled={formRoles.length <= 1}
                aria-label={t('common:permissions-modal.role-placeholder')}
              ></DropdownSelect>
            )}
            <Button type={ButtonType.PRIMARY} onClick={assignUser} disabled={!selectedUser}>
              {t('permissions-modal.manage-users')}
            </Button>
          </div>
        )}
        {requiredRoles.length > 0 && shouldValidateRoles && !isValid && (
          <div>
            <span>{multiRoleMessage}</span>
          </div>
        )}
        <div className="pr-1" style={{ maxHeight: usersMaxHeight }}>
          {onCreatorRoleChange && (
            <div data-cy={`form-creator`} className="flex items-center py-2">
              <ProfileImageStack users={[]} size={ImageSize.S} />
              <div className="mx-4 font-medium">Creator</div>
              <div className="ml-auto">
                <div className="flex w-56 items-center">
                  <DropdownSelect
                    className="w-full min-w-40"
                    data-cy={`form-user-creator-role-options`}
                    value={findRole(creatorRole || noAccessValue)}
                    onChange={(data) =>
                      onCreatorRoleChange(
                        data.value === noAccessValue ? null : (data.value as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole]),
                      )
                    }
                    options={filteredRoles}
                    placeholder={t('common:permissions-modal.role-placeholder')}
                    aria-label={t('common:permissions-modal.role-placeholder')}
                    disabled={filteredRoles.length == 1}
                  ></DropdownSelect>
                  {<div className="w-10" />}
                </div>
              </div>
            </div>
          )}
          {users.map((user) => {
            return (
              <UserAccessRow
                key={user.id}
                user={user}
                onRoleChange={assignedUserRoleChange}
                onUserRemove={unAssignUser}
                roleOptionsFiltered={filteredRoles.filter((x) => x.value !== noAccessValue)}
                rolesOptions={formRoleOptions}
              />
            );
          })}
        </div>
      </>
    );
  } else {
    return (
      <div data-cy="add-users-mode">
        <InviteOrSearchContent
          inviteMode={true}
          filterRoles={inviteRoleFilter}
          onInputsValid={(value) => onUserInviteValid && onUserInviteValid(value)}
          onUserChange={(value) => onUserInviteChange && onUserInviteChange(value)}
          formRoles={filteredRoles.filter((x) => x.value !== noAccessValue)}
          selectedFormRole={findRole(role)}
          onFormRoleChange={(data) => onRoleChange(data.value as (typeof ClientFormUserRole)[keyof typeof ClientFormUserRole])}
          clientId={currentClient?.id}
        />
      </div>
    );
  }
};

export default AccessControl;
